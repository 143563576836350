

































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import PasswordModal from '@/views/common/password-modal.vue'
import TipModal from '@/views/common/tip-modal.vue'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination,
    PasswordModal,
    TipModal
  }
})
export default class Administrator extends Vue {
  $constants: any
  name = ''
  listLoading = false
  userId = ''
  tableHeader = [
    {
      key: 'nickname',
      name: '名字',
      width: '20%'
    },
    {
      key: 'username',
      name: '用户名',
      width: '20%'
    },
    {
      key: 'createdTime',
      name: '添加日期',
      width: '20%'
    },
    {
      key: 'opt',
      name: '操作',
      width: '',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  openWhichModal = ''

  created() {
    this.getAdministratorList()
  }
  searchClick() {
    this.listQuery = {
      page: 1,
      limit: 15
    }
    this.getAdministratorList()
  }

  // 获取用户列表
  getAdministratorList() {
    this.listLoading = true
    this.$api.people
      .getUserLists({
        username: this.name,
        current: this.listQuery.page,
        size: this.listQuery.limit
      })
      .then((res: any) => {
        const data = res.data
        this.listLoading = false
        if (data.success) {
          this.tableData = data.data
          this.total = data.total
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 修改密码
  editPwd(info: any) {
    this.listLoading = true
    this.$api.people
      .editPwd({
        id: this.userId,
        password: info.password,
        rawPassword: info.rawPassword
      })
      .then((res: any) => {
        const data = res.data
        this.listLoading = false
        if (data.success) {
          this.closeDialog()
          this.$message.success(data.msg)
          this.$router.push('/')
          sessionStorage.clear()
          localStorage.clear()
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  deleteUser() {
    // 删除用户
    this.listLoading = true
    this.$api.people
      .deleteUser(this.userId)
      .then((res: any) => {
        this.listLoading = false
        if (res.data.success) {
          this.closeDialog()
          this.$message.success(res.data.msg)
          this.getAdministratorList()
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 关闭对话框
  closeDialog() {
    this.openWhichModal = ''
  }

  toDetail(item?: any) {
    if (item && item.id) {
      this.$router.push('/people/administrator-edit?id=' + item.id)
    } else {
      this.$router.push('/people/administrator-edit')
    }
  }
}
